import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.sub.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { SysAccountApi } from "@/api";
import Decimal from "decimal.js";
export default {
  name: "userFreeze",
  components: {},
  props: {
    uid: [String, Number]
  },
  data: function data() {
    return {
      item: {},
      submiting: false,
      loading: false,
      confirm: false
    };
  },
  computed: {
    balance: function balance() {
      if (Object.keys(this.item).length && this.item.cbkFreezeAmount !== "" && !isNaN(this.item.cbkFreezeAmount)) {
        var b = new Decimal(this.item.cbkFreezeAmount).sub(new Decimal(this.item.cbkFreezeAmountOld)).valueOf();
        return Number(b);
      }
      return 0;
    }
  },
  created: function created() {
    this.find();
  },
  mounted: function mounted() {},
  methods: {
    validateCBKFreezeAmount: function validateCBKFreezeAmount(rule, value, callback) {
      var val = Number(value);
      if (val < 0) {
        callback("用户冻结CBK余额不能小于0");
      } else if (val > this.item.cbkNum) {
        callback("用户冻结CBK余额不能大于总CBK余额");
      }
      callback();
    },
    find: function find() {
      var _this = this;
      this.loading = true;
      SysAccountApi.getUserCBKBalance(this.uid).then(function (res) {
        res.cbkFreezeAmountOld = res.cbkFreezeAmount;
        _this.item = res;
      }).catch(function (err) {
        // this.disabledLoading = true;
        _this.toast(err || "接口报错", "error");
      }).finally(function () {
        _this.loading = false;
      });
    },
    cancle: function cancle() {
      this.$emit("cancel");
    },
    submitConfirm: function submitConfirm() {
      var _this2 = this;
      if (this.balance == 0) {
        this.confirm = false;
        this.$emit("submit");
        return;
      }
      this.submiting = true;
      var method = this.balance > 0 ? "freezeCBK" : "unfreezeCBK";
      SysAccountApi[method]({
        uid: this.uid,
        bricks: 1,
        amount: Math.abs(this.balance)
      }).then(function (res) {
        _this2.toast("".concat(method == "freezeCBK" ? "冻结" : "解冻", "\u6210\u529F"), "success");
        _this2.$emit("submit");
      }).catch(function (err) {
        _this2.toast((err === null || err === void 0 ? void 0 : err.msg) || "".concat(method == "freezeCBK" ? "冻结" : "解冻", "\u5931\u8D25"), "error");
      }).finally(function () {
        _this2.submiting = false;
      });
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs.dcmoduleForm.validate(function (valid) {
        if (valid) {
          _this3.confirm = true;
          _this3.submiting = false;
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    }
  }
};