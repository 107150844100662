var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.updateStatus == 1 ? _c("span", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.type == "status" ? "确认启用账号吗" : "确认停发CBK吗") + "?")]) : _vm._e(), _vm.updateStatus == 0 ? _c("span", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.type == "status" ? "确认停用账号吗" : "确认续发CBK吗") + "?")]) : _vm._e(), _c("div", {
    staticClass: "constainer"
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#f5f5f5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };