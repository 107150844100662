import "core-js/modules/es.number.constructor.js";
export default {
  name: "RestStatus",
  components: {},
  props: {
    updateStatus: String | Number,
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      // 字段
      type: String,
      default: 'status'
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  created: function created() {},
  methods: {
    cancle: function cancle() {
      this.$emit("handleAddCancle");
    },
    submit: function submit() {
      this.$emit("handleAddSubmit", this.type);
    }
  }
};